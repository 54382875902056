import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImg from "../components/common/BackgroundImg";
import PageTitle from "../components/common/PageTitle";
import CardContainer from "../components/common/CardContainer";
import InformationCard from "../components/common/InformationCard";
import { Form, Button, Col } from "react-bootstrap";
import contactDataFr from "../../static/content/contactFr.yml";
import contactDataEn from "../../static/content/contactEn.yml";
import Languages from "../referentials/Languages";

export default (props) => {
  const data = useStaticQuery(graphql`
  query contactImg {
    image: file(relativePath: {eq: "contactImg/train-montenvers.jpg"})  {
      childImageSharp {
        fluid(maxWidth: 3500, maxHeight: 1900, quality: 80, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`);

  let contactData = null;
  switch (props.language) {
    case Languages.fr:
      contactData = contactDataFr;
      break;
    default:
      contactData = contactDataEn;
  }


  const buttonStyle = {
    marginBottom: "10px"
  }

  return (
    <>
      <BackgroundImg imgFluid={data.image.childImageSharp.fluid} />
      <CardContainer>
        <PageTitle {...props} />
        <InformationCard {...contactData} />
        <Form action="https://formsubmit.co/5824cf8cd0d93592002c98e2a267c1ce" method="POST">
          <input type="text" name="_honey" style={{ display: "none" }} />
          <input type="hidden" name="_autoresponse" value={contactData.autoResponse}></input>
          <Form.Row>
            <Col>
              <Form.Label>{contactData.firstName}</Form.Label>
              <Form.Control placeholder={contactData.firstName} name="firstname" required />
            </Col>
            <Col>
              <Form.Label>{contactData.lastName}</Form.Label>
              <Form.Control placeholder={contactData.lastName} name="lastname" required />
            </Col>
          </Form.Row>
          <Form.Group controlId="email">
            <Form.Label>{contactData.email}</Form.Label>
            <Form.Control type="email" placeholder={contactData.email} name="email" required />
          </Form.Group>
          <Form.Group controlId="object">
            <Form.Label>{contactData.object}</Form.Label>
            <Form.Control placeholder={contactData.object} name="_subject" required />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Label>{contactData.message}</Form.Label>
            <Form.Control as="textarea" rows={6} placeholder={contactData.message} name="message" required />
          </Form.Group>
          <Button variant="primary" type="submit" style={buttonStyle}>
            {contactData.sendLabel}
          </Button>
        </Form>
      </CardContainer>
    </>
  );
}
import React from "react";

export const textToJSX = (str) => {
  const out = str.trim().split("\n\n").map((paragraph, paragraphIndex) => {
    const outText = paragraph.split("\n").map((text, blockIndex) => {
      return <span key={paragraphIndex + blockIndex}>{text}<br /></span>;
    });
    return <p key={paragraphIndex}>{outText}</p>;
  });
  return out;
}
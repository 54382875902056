import React from "react";
import Card from "react-bootstrap/Card";
import { textToJSX } from "../../utils/TextFormatter";

export default ({ text }) => {

  const cardBodyStyle = {
    textAlign: "left"
  };

  return (
    <Card.Body>
      <div style={cardBodyStyle}>{textToJSX(text)}</div>
    </Card.Body>
  );
}